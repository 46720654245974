export default () => {
    const store = useBreadcrumbStore();

    const { elements } = storeToRefs(store);

    function add(entry: TBreadcrumb) {
        const id = store.add(entry);

        onBeforeUnmount(() => {
            store.remove(id);
        });

        onBeforeRouteLeave(() => {
            store.remove(id);
        });
    }

    return {
        add,
        elements,
    };
};
