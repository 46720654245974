export default defineStore('breadcrumb', () => {
    const elements: Ref<TBreadcrumb[]> = ref([]);
    const uuid = useUUID();

    function add(entry: TBreadcrumb): string {
        const id = uuid();

        elements.value.push({
            ...entry,
            id,
        } as TBreadcrumb);

        return id;
    }

    function remove(id: string) {
        elements.value = elements.value?.filter((e) => e.id !== id) || [];
    }

    return {
        elements,
        add,
        remove,
    };
});
